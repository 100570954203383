import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ConstructionImage from "../components/image_construction"

const NotFoundPage = () => (

  <Layout>
    <SEO title="404: Not found" />

    <div className="content1">
      <h1>MISSING PAGE / VERMISSTE SEITE</h1>
        <p>Please inform us, that the link is incorrect. Or respectively: that the page is missing.</p>

          <div className="construction">
              <ConstructionImage alt="Bild mit Arbeitern"/>
          </div>

        <p>Bitte informieren Sie uns, dass die Verknüpfung inkorrekt ist bzw. die Seite vermisst wird.</p>
    </div>

  </Layout>
)

export default NotFoundPage
